<template>
  <Screening
    class="elv-account-screening-container"
    iconLength="14"
    :exportText="props.exportText"
    buttonTextColor="#636B75"
    :showIntegrations="true"
  >
    <template #screening>
      <div v-if="props.tableType === 'Contact'" class="elv-accounts-screening-counterparty-tabs">
        <router-link
          :to="{ name: 'entity-accounts-contacts-named' }"
          :class="{ 'is-selected': route.name === 'entity-accounts-contacts-named' }"
          class="elv-accounts-screening-counterparty-tabs__item"
        >
          {{ t('report.named') }}</router-link
        >
        <router-link
          :to="{ name: 'entity-accounts-contacts-unnamed' }"
          :class="{ 'is-selected': route.name === 'entity-accounts-contacts-unnamed' }"
          class="elv-accounts-screening-counterparty-tabs__item"
        >
          {{ t('report.unnamed') }}</router-link
        >
      </div>
      <div
        v-if="!['Accounts', 'Contact', 'Derivative', 'DefiPosition', 'TotalPosition'].includes(props.tableType)"
        class="elv-accounts-screening-item"
      >
        <span class="elv-accounts-screening-item__label"
          >{{ props.tableType === 'Counterparty' ? t('common.type') : t('common.view') }}:</span
        >
        <el-select
          v-model="typeValue"
          placeholder=" "
          popper-class="elv-accounts-screening-popper"
          class="elv-accounts-screening-view"
          :class="{ 'elv-select-short': props.tableType !== 'Accounts' }"
        >
          <el-option v-for="item in options" :key="item.value" :label="transformI18n(item.label)" :value="item.value" />
        </el-select>
      </div>
      <div
        v-if="['Accounts', 'Derivative', 'DefiPosition', 'TotalPosition'].includes(props.tableType)"
        class="elv-accounts-screening-item"
      >
        <div class="elv-accounts-screening-item__label">
          {{ ['Accounts', 'TotalPosition'].includes(props.tableType) ? t('common.dateTime') : t('common.date') }}
        </div>
        <div
          ref="dateTimeRef"
          v-click-outside="onCloseDateTime"
          class="elv-accounts-screening-datetime__value-content"
          style="margin-right: 16px"
          @click="onOpenDateTime"
        >
          <div class="elv-accounts-screening-datetime__value">
            <span v-if="dateTimeValue"
              >{{
                props.tableType === 'TotalPosition'
                  ? dayjs(dateTimeValue)?.format('YYYY/MM/DD HH:mm:ss')
                  : dayjs(dateTimeValue)?.format('YYYY/MM/DD')
              }}
              &nbsp;{{ currentEntityTimezone }}</span
            >
          </div>
          <SvgIcon name="events-arrow" width="14" height="14" :style="{ transform: rotate }" />
        </div>
        <div v-show="false">
          <el-date-picker
            ref="popoverRef"
            v-model="dateTimeValue"
            popper-class="elv-accounts-screening-dateTime-popper"
            :virtual-ref="dateTimeRef"
            virtual-triggering
            :type="props.tableType === 'TotalPosition' ? 'datetime' : 'date'"
            :value-format="props.tableType === 'TotalPosition' ? 'YYYY-MM-DD HH:00:00' : 'YYYY-MM-DD'"
            :format="props.tableType === 'TotalPosition' ? 'YYYY/MM/DD HH:00:00' : 'YYYY/MM/DD'"
            :clearable="false"
            time-format="HH"
            date-format="MMM DD, YYYY"
            :disabled-minutes="() => Array.from({ length: 60 }, (_, i) => i)"
            :disabled-date="disabledDate"
            :default-value="defaultTime"
            @change="onChangeTime"
          />
        </div>
        <TextSelectFilter
          v-if="['DefiPosition', 'Derivative'].includes(props.tableType)"
          v-model="group"
          popper-class="elv-accounts-screening-subgroup-popper"
          :title="t('common.group')"
          :options="groupOptions"
          @change="onChangeGroup"
        />
        <TextSelectFilter
          v-if="!['TotalPosition'].includes(props.tableType)"
          v-model="subGroup"
          popper-class="elv-accounts-screening-subgroup-popper"
          :title="t('common.subgroup')"
          :options="subGroupOptions"
          @change="onChangeSubGroup"
        />
        <TextSelectFilter
          v-if="props.tableType === 'Accounts'"
          v-model="accountSort"
          :title="t('common.sort')"
          :options="sortOptions"
          @change="onChangeSort"
        />

        <template v-if="props.tableType === 'DefiPosition'">
          <div @click="emit('onOpenFilterOverlay')">
            <div v-if="!props.filterTotal" class="elv-accounts-screening-item-filter-add">
              <SvgIcon name="add-line" width="14" height="14" />{{ t('common.addFilter') }}
            </div>
            <div v-else class="elv-accounts-screening-item-filter-count">
              {{ t('common.filter') }}: {{ props.filterTotal }}
            </div>
          </div>
        </template>
      </div>
    </template>

    <template #operating>
      <Operating
        v-if="['Accounts', 'Derivative', 'DefiPosition', 'TotalPosition'].includes(props.tableType)"
        :tableType="props.tableType"
        :exportParams="exportParams"
        :exportText="props.exportText"
      />
      <slot name="button" />
    </template>
  </Screening>
</template>

<script setup lang="ts">
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { useI18n } from 'vue-i18n'
import { isEmpty } from 'lodash-es'
import Operating from './Operating.vue'
import { $t, transformI18n } from '@/i18n'
import timezoneList from '@/config/timezone'
import timezone from 'dayjs/plugin/timezone'
import { useEntityStore } from '@/stores/modules/entity'
import { exportProgressStatusType } from '@/config/global'
import { useAccountStore } from '@/stores/modules/accounts'
import { ClickOutside as vClickOutside } from 'element-plus'
import TextSelectFilter from '@/components/Base/Filter/TextSelect.vue'
import Screening from '@/pages/Financials/Project/components/Screening.vue'

const { t } = useI18n()
const route = useRoute()
const entityStore = useEntityStore()
const accountStore = useAccountStore()

const props = defineProps({
  tableType: {
    type: String,
    required: true
  },
  auxiliaryTypeList: {
    type: Array,
    default: () => []
  },
  filterTotal: {
    type: Number,
    default: 0
  },
  exportText: {
    type: String,
    default: $t('button.export')
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)

const popoverRef = ref()
const dateTimeRef = ref()
const typeValue = ref('LIST')
const group = ref('DERIVATIVE_TYPE')
const subGroup = ref('NONE')
const accountSort = ref('BALANCE')
const dateTimeValue: any = ref('')
const rotate = ref('rotate(90deg)')

const sourcesOptions = [
  {
    value: 'LIST',
    label: $t('common.list')
  }
]

const accountsOptions = [
  {
    value: 'GROUP',
    label: 'Group by Type'
  }
]

const sortOptions = ref([
  {
    label: $t('project.dashboard.totalBalance'),
    value: 'BALANCE'
  },
  {
    label: $t('common.name'),
    value: 'NAME'
  }
])

const counterpartyOptions = [
  {
    value: 'ALL',
    label: $t('common.all')
  }
]

const emit = defineEmits([
  'onChangeAccountFilter',
  'onChangeDerivativeFilter',
  'onChangeDeFiPositionFilter',
  'onOpenFilterOverlay'
])

const entityId = computed(() => {
  return route.params?.entityId as string
})

const groupOptions = computed(() => {
  if (props.tableType === 'DefiPosition') {
    return [
      {
        label: $t('common.account'),
        value: 'ENTITY_ACCOUNT'
      },
      {
        label: $t('common.protocol'),
        value: 'PROTOCOL'
      },
      {
        label: $t('common.type'),
        value: 'TYPE'
      }
    ]
  }
  return [
    {
      label: 'Derivative Type',
      value: 'DERIVATIVE_TYPE'
    },
    {
      label: $t('common.account'),
      value: 'ENTITY_ACCOUNT'
    }
  ]
})
const subGroupOptions = computed(() => {
  if (props.tableType === 'DefiPosition') {
    return [
      {
        label: $t('common.account'),
        value: 'ENTITY_ACCOUNT'
      },
      {
        label: $t('common.protocol'),
        value: 'PROTOCOL'
      },
      {
        label: $t('common.type'),
        value: 'TYPE'
      }
    ]
  }
  const list = [
    {
      label: 'None',
      value: 'NONE'
    }
  ]
  if (props.tableType === 'Accounts') {
    list.push({
      label: 'Platform',
      value: 'PLATFORM'
    })

    if (props.auxiliaryTypeList.length) {
      props.auxiliaryTypeList
        .filter((auxiliaryType: any) => auxiliaryType.type !== 'ENTITY_ACCOUNT')
        .forEach((item: any) => {
          list.push({
            label: item.name,
            value: item.auxiliaryTypeId
          })
        })
    }
  }
  if (props.tableType === 'Derivative') {
    if (group.value === 'DERIVATIVE_TYPE') {
      list.push({
        label: $t('common.account'),
        value: 'ENTITY_ACCOUNT'
      })
    } else {
      list.push({
        label: 'Derivative Type',
        value: 'DERIVATIVE_TYPE'
      })
    }
  }
  return list
})

const currentEntityTimezone = computed(() => {
  return timezoneList.find((item) => item.area === entityStore.entityDetail?.timezone)?.timezone ?? 'UTC+00:00'
})

const exportParams = computed(() => {
  switch (props.tableType) {
    case 'Accounts':
      const accountFilter: any =
        accountStore.accountFilterList.find((item: any) => {
          return item.entityId === entityId.value
        }) ?? {}
      const accountParams = {
        date: dateTimeValue.value,
        subGroup: 'NONE',
        sort: 'BALANCE',
        ...accountFilter?.data
      }
      if (!['NONE', 'PLATFORM'].includes(accountParams.subGroup) && accountFilter?.data?.auxiliaryType?.type) {
        accountParams.subGroup = accountFilter?.data?.auxiliaryType?.type
        accountParams.auxiliaryTypeId = accountFilter?.data?.auxiliaryType?.auxiliaryTypeId
        delete accountParams.auxiliaryType
      }
      return {
        type: exportProgressStatusType.treasury.value,
        extra: accountParams
      }
    case 'TotalPosition':
      return {
        type: exportProgressStatusType.totalPosition.value,
        extra: {
          datetime: dateTimeValue.value
        }
      }
    case 'Derivative':
      const derivativeFilter: any =
        accountStore.derivativeFilterList.find((item: any) => {
          return item.entityId === entityId.value
        }) ?? {}
      const derivativeParams = {
        date: dateTimeValue.value,
        group: 'DERIVATIVE_TYPE',
        subGroup: 'NONE',
        ...derivativeFilter?.data
      }
      return {
        type: exportProgressStatusType.derivative.value,
        extra: derivativeParams
      }
    case 'DefiPosition':
      const deFiPositionFilter: any =
        accountStore.deFiPositionsFilterList.find((item) => {
          return item.entityId === entityId.value
        }) ?? {}
      const deFiPositionParams = {
        date: dateTimeValue.value,
        group: group.value,
        subGroup: subGroup.value,
        ...deFiPositionFilter?.data
      }
      return {
        type: exportProgressStatusType.defiPosition.value,
        extra: deFiPositionParams
      }
    default:
      break
  }
  return {
    type: '',
    extra: {}
  }
})

const options = computed(() => {
  let data = []
  switch (props.tableType) {
    case 'Accounts':
      data = accountsOptions
      break
    case 'Sources':
      data = sourcesOptions
      break
    default:
      data = counterpartyOptions
      break
  }
  return data
})

const setDateTimeValue = (date: string) => {
  dateTimeValue.value = date
}

const entityTimezone = entityStore.entityDetail?.timezone

const defaultTime = computed(() => {
  const defaultValue =
    props.tableType === 'DefiPosition' ? dayjs().tz(entityTimezone).subtract(1, 'd') : dayjs().tz(entityTimezone)
  if (props.tableType === 'TotalPosition') {
    // 默认当天 00:00:00
    setDateTimeValue(defaultValue.startOf('day').format('YYYY-MM-DD HH:mm:ss'))
    return defaultValue.startOf('day').toDate()
  }

  setDateTimeValue(defaultValue.format('YYYY-MM-DD'))
  return defaultValue.toDate()
})

const disabledDate = (time: Date) => {
  const offset = dayjs().tz(entityTimezone).utcOffset() / 60
  const nowTime = dayjs().utc().add(offset, 'hour').startOf('day')
  const timeInTimeZone = dayjs(time).tz(entityTimezone, true).startOf('day')
  return timeInTimeZone.isAfter(nowTime)
}

const onChangeGroup = (val: string) => {
  let data: any = {}
  if (props.tableType === 'Derivative') {
    const derivativeFilter: any =
      accountStore.derivativeFilterList.find((item: any) => {
        return item.entityId === entityId.value
      }) ?? {}
    if (!isEmpty(derivativeFilter?.data)) {
      data = derivativeFilter.data
    }
    if (subGroup.value === 'DERIVATIVE_TYPE' && val === 'DERIVATIVE_TYPE') {
      data.subGroup = 'NONE'
      subGroup.value = 'NONE'
    } else if (subGroup.value === 'ENTITY_ACCOUNT' && val === 'ENTITY_ACCOUNT') {
      data.subGroup = 'NONE'
      subGroup.value = 'NONE'
    }
    data.group = val
    accountStore.editDerivativeFilterList(entityId.value, data)
    emit('onChangeDerivativeFilter')
  }
  if (props.tableType === 'DefiPosition') {
    const deFiPositionFilter: any =
      accountStore.deFiPositionsFilterList.find((item: any) => {
        return item.entityId === entityId.value
      }) ?? {}
    if (!isEmpty(deFiPositionFilter?.data)) {
      data = deFiPositionFilter.data
    }
    if (subGroup.value === 'PROTOCOL' && val === 'PROTOCOL') {
      data.subGroup = 'ENTITY_ACCOUNT'
      subGroup.value = 'ENTITY_ACCOUNT'
    } else if (subGroup.value === 'ENTITY_ACCOUNT' && val === 'ENTITY_ACCOUNT') {
      data.subGroup = 'PROTOCOL'
      subGroup.value = 'PROTOCOL'
    } else if (subGroup.value === 'TYPE' && val === 'TYPE') {
      data.subGroup = 'ENTITY_ACCOUNT'
      subGroup.value = 'ENTITY_ACCOUNT'
    }
    data.group = val

    accountStore.editDeFiPositionsFilterList(entityId.value, data)
    emit('onChangeDeFiPositionFilter')
  }
}

const onChangeSubGroup = (val: string) => {
  let data: any = {}
  if (props.tableType === 'Accounts') {
    const accountFilter: any =
      accountStore.accountFilterList.find((item: any) => {
        return item.entityId === entityId.value
      }) ?? {}
    if (!isEmpty(accountFilter?.data)) {
      data = accountFilter.data
    }
    switch (val) {
      case 'PLATFORM':
        data.subGroup = val
        delete data.auxiliaryType
        break
      case 'NONE':
        delete data.subGroup
        delete data.auxiliaryType
        break
      default:
        // 辅助核算字段
        if (props.auxiliaryTypeList.length) {
          const auxiliaryType: any =
            props.auxiliaryTypeList.find((item: any) => {
              return item.auxiliaryTypeId === val
            }) ?? {}
          if (auxiliaryType?.type) {
            data.auxiliaryType = {
              auxiliaryTypeId: val,
              type: auxiliaryType?.type === 'ITEM' ? 'AUXILIARY_ITEM' : auxiliaryType?.type
            }
          }
          data.subGroup = val
        }
        break
    }
    accountStore.editAccountFilter(entityId.value, data)
    emit('onChangeAccountFilter')
  }
  if (props.tableType === 'Derivative') {
    const derivativeFilter: any =
      accountStore.derivativeFilterList.find((item: any) => {
        return item.entityId === entityId.value
      }) ?? {}
    if (!isEmpty(derivativeFilter?.data)) {
      data = derivativeFilter.data
    }
    if (group.value === 'DERIVATIVE_TYPE' && val === 'DERIVATIVE_TYPE') {
      data.group = 'NONE'
      group.value = 'NONE'
    } else if (group.value === 'ENTITY_ACCOUNT' && val === 'ENTITY_ACCOUNT') {
      data.group = 'NONE'
      group.value = 'NONE'
    } else {
      data.subGroup = val
    }
    accountStore.editDerivativeFilterList(entityId.value, data)
    emit('onChangeDerivativeFilter')
  }

  if (props.tableType === 'DefiPosition') {
    const deFiPositionFilter: any =
      accountStore.deFiPositionsFilterList.find((item: any) => {
        return item.entityId === entityId.value
      }) ?? {}
    if (!isEmpty(deFiPositionFilter?.data)) {
      data = deFiPositionFilter.data
    }

    if (group.value === 'PROTOCOL' && val === 'PROTOCOL') {
      data.group = 'ENTITY_ACCOUNT'
      group.value = 'ENTITY_ACCOUNT'
    } else if (group.value === 'ENTITY_ACCOUNT' && val === 'ENTITY_ACCOUNT') {
      data.group = 'PROTOCOL'
      group.value = 'PROTOCOL'
    } else if (group.value === 'TYPE' && val === 'TYPE') {
      data.group = 'ENTITY_ACCOUNT'
      group.value = 'ENTITY_ACCOUNT'
    }
    data.subGroup = val

    accountStore.editDeFiPositionsFilterList(entityId.value, data)
    emit('onChangeDeFiPositionFilter')
  }
}

const onChangeSort = (val: string) => {
  let data: any = {}
  const accountFilter: any =
    accountStore.accountFilterList.find((item: any) => {
      return item.entityId === entityId.value
    }) ?? {}
  if (!isEmpty(accountFilter?.data)) {
    data = accountFilter.data
  }
  if (val === 'NAME') {
    data.sort = val
  } else {
    delete data.sort
  }
  accountStore.editAccountFilter(entityId.value, data)
  emit('onChangeAccountFilter')
}

const onOpenDateTime = () => {
  rotate.value = rotate.value === 'rotate(90deg)' ? 'rotate(270deg)' : 'rotate(90deg)'
  if (rotate.value === 'rotate(90deg)') {
    unref(popoverRef)?.handleClose?.()
  } else {
    unref(popoverRef)?.handleOpen?.()
  }
}

const onCloseDateTime = () => {
  rotate.value = 'rotate(90deg)'
  unref(popoverRef)?.handleClose?.()
}

const onChangeTime = () => {
  rotate.value = 'rotate(90deg)'
  emit('onChangeAccountFilter')
}

defineExpose({ dateTimeValue, group, subGroup, accountSort })

watch(
  () => props.tableType,
  () => {
    switch (props.tableType) {
      case 'Accounts':
        typeValue.value = 'GROUP'
        break
      case 'Sources':
        typeValue.value = 'LIST'
        break
      default:
        typeValue.value = 'ALL'
        break
    }
  },
  { immediate: true }
)

watch(
  [() => props.tableType, () => props.auxiliaryTypeList],
  () => {
    switch (props.tableType) {
      case 'Accounts':
        const accountFilter: any =
          accountStore.accountFilterList.find((item: any) => {
            return item.entityId === entityId.value
          }) ?? {}
        if (!isEmpty(accountFilter?.data)) {
          subGroup.value = accountFilter.data.subGroup ?? 'NONE'
          accountSort.value = accountFilter.data.sort ?? 'BALANCE'
        } else {
          subGroup.value = 'NONE'
          accountSort.value = 'BALANCE'
        }
        if (
          !['NONE', 'PLATFORM'].includes(subGroup.value) &&
          accountFilter?.data?.auxiliaryType?.type &&
          props.auxiliaryTypeList.length
        ) {
          const auxiliaryType = props.auxiliaryTypeList.find(
            (item: any) => item.auxiliaryTypeId === accountFilter?.data?.auxiliaryType?.auxiliaryTypeId
          )
          if (isEmpty(auxiliaryType)) {
            subGroup.value = 'NONE'
            let data: any = {}
            data = accountFilter.data
            delete data.subGroup
            delete data.auxiliaryType
          }
        }
        break
      case 'Derivative':
        const derivativeFilter: any =
          accountStore.derivativeFilterList.find((item: any) => {
            return item.entityId === entityId.value
          }) ?? {}
        if (!isEmpty(derivativeFilter?.data)) {
          group.value = derivativeFilter.data?.group ?? 'DERIVATIVE_TYPE'
          subGroup.value =
            derivativeFilter.data?.subGroup ?? (group.value === 'ENTITY_ACCOUNT' ? 'NONE' : 'ENTITY_ACCOUNT')
        } else {
          group.value = 'DERIVATIVE_TYPE'
          subGroup.value = 'ENTITY_ACCOUNT'
        }
        break
      case 'DefiPosition':
        const deFiPositionFilter: any =
          accountStore.deFiPositionsFilterList.find((item: any) => {
            return item.entityId === entityId.value
          }) ?? {}
        if (!isEmpty(deFiPositionFilter?.data)) {
          group.value = deFiPositionFilter.data?.group ?? 'ENTITY_ACCOUNT'
          subGroup.value = deFiPositionFilter.data?.subGroup ?? 'PROTOCOL'
        } else {
          group.value = 'ENTITY_ACCOUNT'
          subGroup.value = 'PROTOCOL'
        }
        break
      default:
        break
    }
  },
  { immediate: true }
)
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.elv-account-screening-container.elv-screening-container {
  padding: 9px 4px 10px 20px;
  position: relative;
}

.elv-accounts-screening-counterparty-tabs {
  display: flex;
  align-items: center;
  gap: 12px;

  .elv-accounts-screening-counterparty-tabs__item {
    display: flex;
    justify-content: center;
    padding: 0px 16px;
    box-sizing: border-box;
    height: 27px;
    align-items: center;
    border-radius: 20px;
    background: #fff;
    color: #636b75;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    &.is-selected {
      color: #1343bf;
      font-weight: 600;
      background-color: #e5edff;
    }

    &:not(.is-selected):hover {
      background-color: #f9fafb;
    }
  }
}

.elv-accounts-screening-item {
  padding-right: 16px;
  border-right: 1px solid #dde1e6;
  display: flex;
  align-items: center;
  width: fit-content;

  &:last-child {
    border-right: none;
  }

  .elv-base-text-select-item .elv-base-text-select-item__label {
    white-space: nowrap;
  }

  .elv-accounts-screening-item__label {
    color: #838d95;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
  }

  .el-select {
    height: 15px;
    width: fit-content;

    .el-select__wrapper {
      height: 15px;
      min-height: 15px;
      border: none !important;
      box-shadow: none !important;
      padding: 0;
      padding-left: 8px;

      .el-select__selection {
        width: fit-content;

        .el-select__placeholder {
          position: relative;
          top: 0;
          transform: none;
          color: #1e2024;
          font-family: 'Plus Jakarta Sans';
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: fit-content;
          overflow: visible;
        }
      }

      .el-select__suffix {
        .el-icon {
          margin-left: 4px;

          svg {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .el-select__suffix .el-icon {
      color: #0e0f11;
    }
  }

  .elv-accounts-screening-datetime__value-content {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;

    svg {
      margin-top: 2px;
      transition: transform 0.3s ease-in-out;
    }
  }

  .elv-accounts-screening-datetime__value {
    color: #1e2024;
    margin-right: 4px;
    margin-left: 8px;

    span {
      color: #1e2024;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .elv-accounts-screening-item-filter-add {
    display: flex;
    align-items: center;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    svg {
      fill: #939ba2;
      margin-right: 8px;
    }

    &:hover {
      cursor: pointer;

      svg {
        fill: #1e2024;
      }
    }
  }

  .elv-accounts-screening-item-filter-count {
    display: flex;
    padding: 0px 11px;
    height: 19px;
    box-sizing: border-box;
    align-items: center;
    border-radius: 10px;
    background: #5e85eb;
    color: #fff;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
  }
}

.elv-accounts-screening-dateTime-popper {
  &.el-popper {
    transform: translateY(-11.5px);
    border: 1px solid #e4e7eb;
    box-shadow:
      0px 8px 16px rgba(0, 0, 0, 0.04),
      0px 4px 8px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    // overflow: hidden;
    max-width: fit-content;
  }

  .el-picker-panel__body-wrapper {
    display: flex;
    flex-direction: column-reverse;
  }

  .el-date-range-picker__content.is-left {
    border-right: 0px;
  }

  .el-date-range-picker .el-picker-panel__content {
    padding: 8px;
  }

  .el-date-picker {
    width: fit-content;

    :has(.el-date-picker__time-header) {
      width: 237px;
    }

    :has(.el-month-table),
    :has(.el-year-table) {
      width: 322px;
    }

    .el-picker-panel__content {
      width: 221px;
      margin: 0;
      margin-top: 6px;

      &:has(.el-month-table),
      &:has(.el-year-table) {
        width: fit-content;
      }

      .el-date-table {
        th {
          min-width: 31.57px;
          height: 16px;
          border-bottom: 0px;
          padding: 0px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 11px;
          line-height: 11px;
          color: #aaafb6;
        }

        tr {
          color: #636b75;
        }

        td {
          padding: 0px;
          // width: 31.57px;
          // height: 27.8px;
          width: fit-content !important;
          height: fit-content !important;
          border-radius: 2px;

          &.today .el-date-table-cell__text {
            color: #1e2024;
          }

          &.available:hover {
            border-radius: 2px;
            background: #edf0f3;

            .el-date-table-cell__text {
              color: #1e2024;
              font-weight: 600;
            }
          }

          &.next-month {
            display: none;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          &.prev-month {
            visibility: hidden;

            &:hover {
              border-radius: 2px;
              background: #edf0f3;

              .el-date-table-cell__text {
                color: #1e2024;
                font-weight: 600;
              }
            }
          }

          .el-date-table-cell {
            padding: 0px;
            width: 31.57px;
            height: 27.8px;

            .el-date-table-cell__text {
              width: 31.57px;
              height: 27.8px;
              font-family: 'Barlow';
              font-weight: 500;
              font-size: 13px;
              line-height: 13px;
              display: flex;
              align-items: center;
              justify-content: center;
              border-radius: 0px;
            }
          }

          &.in-range .el-date-table-cell {
            background-color: #fff;
          }

          &.current .el-date-table-cell {
            margin-left: 0px;
            border-radius: 0px;

            .el-date-table-cell__text {
              color: #0e0f11;
              font-weight: 600;
              background-color: #fff;
              box-sizing: border-box;
              border-bottom: 1px solid #1343bf;
            }
          }
        }
      }
    }

    .el-picker-panel__body {
      padding: 8px;

      .el-date-picker__time-header {
        padding: 0px;
        padding-bottom: 8px;

        .el-input {
          height: 28px;

          .el-input__inner {
            color: #0e0f11;
            font-family: 'Barlow';
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 28px;
          }
        }
      }

      .el-time-panel {
        left: -75px;
      }
    }

    .el-date-picker__header {
      margin: 0;
      padding: 0;
      width: 100%;
    }

    .el-date-picker__header-label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 500;
      font-size: 13px;
      line-height: 14px;
      color: #aaafb6;
      padding: 0;
      position: relative;
      top: 4px;

      &:nth-child(3) {
        font-weight: 700;
        color: #1e2024;
        margin-left: 4px;
      }
    }
  }

  .el-date-range-picker {
    &.has-time {
      width: 392px;
      min-height: 245px;
      max-height: 273px;
      overflow: hidden;
      background: #ffffff;
    }

    .el-picker-panel__body {
      min-width: 392px;
    }
  }

  .el-picker-panel__icon-btn {
    margin-top: 10.5px;
  }

  .el-date-range-picker__header,
  .el-date-picker__header {
    height: 34px;
    border-bottom: 1px solid #edf0f3;

    div {
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 700;
      font-size: 13px;
      line-height: 34px;
      color: #1e2024;
      margin: 0px;
    }
  }

  .el-picker-panel__sidebar {
    display: flex;
    border: 0px;
    bottom: -30px;
    left: 8px;
    padding-top: 0;
    z-index: 1;
    height: 21px;
    width: auto;
    top: auto;

    .el-picker-panel__shortcut {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      padding: 4px 10px;
      min-width: 65px;
      max-width: 73px;
      width: auto;
      height: 21px;
      border: 1px solid #d0d4d9;
      border-radius: 11px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
      color: #636b75;
      margin-right: 4px;

      &:hover {
        background: #e6edff;
        border: 1px solid #5e85eb;
        color: #1753eb;
        font-weight: 600;
      }
    }
  }

  .el-picker-panel__body {
    margin-left: 0;
    height: auto;

    .el-date-range-picker__time-header {
      display: none;
    }
  }

  .el-date-table {
    th {
      min-width: 25.71px;
      height: 16px;
      border-bottom: 0px;
      padding: 0px;
      font-family: 'Barlow';
      font-weight: 500;
      font-size: 11px;
      line-height: 11px;
      color: #aaafb6;
    }

    tr {
      color: #636b75;
    }

    td {
      padding: 0px;
      width: 25.71px;
      height: 27.8px;
      border-radius: 2px;

      &.today .el-date-table-cell__text {
        color: #1e2024;
      }

      &.available:hover {
        border-radius: 2px;
        background: #edf0f3;

        .el-date-table-cell__text {
          color: #1e2024;
          font-weight: 600;
        }
      }

      &.next-month {
        display: none;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      &.prev-month {
        visibility: hidden;

        &:hover {
          border-radius: 2px;
          background: #edf0f3;

          .el-date-table-cell__text {
            color: #1e2024;
            font-weight: 600;
          }
        }
      }

      .el-date-table-cell {
        padding: 0px;
        width: 25.71px;
        height: 27.8px;

        .el-date-table-cell__text {
          width: 25.71px;
          height: 27.8px;
          font-family: 'Barlow';
          font-weight: 500;
          font-size: 13px;
          line-height: 13px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0px;
        }
      }

      &.in-range .el-date-table-cell {
        background-color: #fff;
      }

      &.start-date .el-date-table-cell {
        margin-left: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }

      &.end-date .el-date-table-cell {
        margin-right: 0px;
        border-radius: 0px;

        .el-date-table-cell__text {
          border-right: 0px;
          color: #0e0f11;
          font-weight: 600;
          background-color: #fff;
          box-sizing: border-box;
          border-bottom: 1px solid #1343bf;
        }
      }
    }
  }

  .el-picker-panel__footer {
    padding: 0px;
    margin: 0 8px;
    padding-top: 8px;
    box-sizing: border-box;
    height: 37px;

    .el-picker-panel__link-btn {
      height: 21px;
      margin-top: -7px;
    }
  }
}

.elv-accounts-screening-subgroup-popper.el-popper {
  transform: translateY(-6.5px);

  .el-select-dropdown__item {
    &:nth-child(2) {
      border-top: 1px solid #edf0f3;
      border-bottom: 1px solid #edf0f3;
    }
  }
}

.elv-accounts-screening-popper.el-popper.el-select__popper {
  transform: translateY(-6.5px);

  .el-select-dropdown__item {
    height: 32px;
    color: #1e2024;
    font-family: 'Plus Jakarta Sans';
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.hover,
    &.is-hovering {
      background: #f9fafb;
    }

    &.selected,
    &.is-selected {
      color: #1753eb;
      font-weight: 500;
    }

    &.is-disabled {
      background-color: #f5f7fa !important;
    }
  }
}
</style>
